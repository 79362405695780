<template>
  <div>
    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded mb-6"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
          >
            <app-date-picker
              v-model="queryParams.start_date"
              hide-details
              no-title
              classes="small-input"
              :label="$t('Start Date')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
          >
            <app-date-picker
              v-model="queryParams.end_date"
              hide-details
              no-title
              classes="small-input"
              :label="$t('End Date')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="auto"
          >
            <app-branch-select
              v-model="queryParams.branch_id"
              classes="small-input"
            />
          </v-col>

          <v-col
            cols="12"
            sm="5"
            md="5"
            lg="4"
          >
            <v-autocomplete
              v-model="queryParams.supplier_id"
              :label="$t('Supplier')"
              :items="suppliersList"
              :item-text="item => item.name"
              :item-value="item => item.id"
              outlined
              dense
              hide-details
              class="small-input"
              :color="$_input_color()"
              @input="supplierSelected(queryParams.supplier_id)"
            />
          </v-col>

          <v-col class="text-end">
            <v-btn
              depressed
              small
              class="me-3"
              @click="$_print()"
            >
              <v-icon left>
                mdil-printer
              </v-icon>

              {{ $t('Print') }}
            </v-btn>

            <v-btn
              color="primary"
              depressed
              small
              @click="getData()"
            >
              <v-icon left>
                mdil-magnify
              </v-icon>

              {{ $t('Search') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded"
    >
      <v-card-text>
        <v-simple-table
          id="print-area"
          class="print-table"
          dense
        >
          <template #default>
            <thead>
              <tr>
                <invoice-header />
              </tr>

              <tr>
                <td
                  colspan="100"
                  class="font-weight-bold d-none d-print-table-cell"
                >
                  <div class="d-flex justify-space-between">
                    <div>
                      {{ $t('Start Date') }}: {{ queryParams.start_date }}
                      <span class="px-2">
                        {{ $t('End Date') }}: {{ queryParams.end_date }}
                      </span>
                    </div>

                    <div>
                      {{ $t('Supplier Name') }}: {{ supplier.name }}
                    </div>
                  </div>
                </td>
              </tr>

              <tr
                v-if="apiData.totals"
                class="font-weight-bold"
              >
                <td colspan="10">
                  <div class="d-flex">
                    <div>
                      {{ $t('Total Quantity') }}: {{ $_format_number(apiData.totals.total_quantity) }}
                    </div>
                    <div class="ps-8">
                      {{ $t('Total Sale Price') }}: {{ $_format_number(apiData.totals.total_sold_price) }}
                    </div>
                  </div>
                </td>
              </tr>

              <tr
                class="grey"
                :class="{
                  'lighten-2': !$vuetify.theme.dark,
                  'darken-3': $vuetify.theme.dark,
                }"
              >
                <th class="text-start">
                  #
                </th>
                <th class="text-start">
                  {{ $t('Item Name') }}
                </th>
                <th class="text-center">
                  {{ $t('Quantity') }}
                </th>
                <th class="text-center">
                  {{ $t('Sale Price') }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(data, index) in apiData.data"
                :key="index"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ data.item_name }}</td>
                <td class="text-center">
                  {{ $_format_number(data.sold_quantity) }}
                </td>
                <td class="text-center">
                  {{ $_format_number(data.sold_price) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      apiData: {},
      queryParams: {},
      supplier: {},
    }
  },

  computed: {
    ...mapGetters(['suppliersList'])
  },

  mounted() {
    this.$_section_title({ title: 'Sold Items Per Supplier', icon: 'mdil-chart-bar' })
    this.getSuppliersList()
  },

  methods: {
    ...mapActions(['getSuppliersList']),

    supplierSelected(id) {
      this.supplier = this.suppliersList.find(supplier => supplier.id == id)
    },

    getData() {
      axios.post('report/sold-items-per-supplier/', this.queryParams).then(res => {
        this.apiData = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>